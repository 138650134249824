import Vue from 'vue';
import type { RawLocation, Route } from 'vue-router';
import Router from 'vue-router';

import DeFiRoiReport from '@/views/reports/DeFiRoiReport.vue';
import TxnLineReport from '@/views/reports/TxnLineReport.vue';

import { ScopeLiterals } from './api-svc-types';
import { store } from './store';
import { isErrorLike } from './utils/guards';
import { checkScope, checkUserRole, isOrgAdminRoute } from './utils/security';

const Home = () => import(/* webpackChunkName: "Home" */ './views/Home.vue');
// const DeFi = () => import(/* webpackChunkName: "Defi" */ './views/DeFi.vue');
const Auth = () => import(/* webpackChunkName: "Auth" */ './views/Auth.vue');
const SignOut = () => import(/* webpackChunkName: "SignOut" */ './views/SignOut.vue');
const Wallets = () => import(/* webpackChunkName: "Wallets" */ './views/wallets/Wallets.vue');
const Transactions = () => import(/* webpackChunkName: "Transactions" */ './views/transactions/index.vue');
const Rules = () => import(/* webpackChunkName: "Rules" */ './views/transactions/Rules.vue');
const Import = () => import(/* webpackChunkName: "Import" */ './views/transactions/Import.vue');
const Matching = () => import(/* webpackChunkName: "Matching" */ './views/transactions/Matching.vue');
const DataImport = () => import(/* webpackChunkName: "Import" */ './views/data/DataImport.vue');
const DataImportReport = () => import(/* webpackChunkName: "Import" */ './views/data/DataImportReport.vue');
const DataFeedReport = () => import(/* webpackChunkName: "Import" */ './views/data/DataFeedReport.vue');
const DataImportWizard = () => import(/* webpackChunkName: "Import" */ './views/data/DataImportWizard.vue');
const DataReconciliation = () => import(/* webpackChunkName: "Import" */ './views/data/Reconciliation.vue');
const Reconciliations = () => import(/* webpackChunkName: "Import" */ './views/reconciliation/Reconciliations.vue');
const Categories = () => import(/* webpackChunkName: "Categories" */ './views/accounting/Categories.vue');
const Contacts = () => import(/* webpackChunkName: "Contacts" */ './views/accounting/Contacts.vue');
const ExternalCostBasis = () =>
  import(/* webpackChunkName: "ExternalCostBasis" */ './views/accounting/ExternalCostBasis.vue');
const CryptoBills = () => import(/* webpackChunkName: "CryptoBills" */ './views/account_receivables/CryptoBills.vue');
const BulkPayment = () => import(/* webpackChunkName: "BulkPayment" */ './views/account_receivables/BulkPayment.vue');
const WrappingTreatment = () =>
  import(/* webpackChunkName: "WrappingTreatment" */ './views/taxes/WrappingTreatment.vue');
const TaxStrategy = () => import(/* webpackChunkName: "TaxStrategy" */ './views/taxes/TaxStrategy.vue');
const Trading = () => import(/* webpackChunkName: "Trading" */ './views/transactions/Trading.vue');
const Taxes = () => import(/* webpackChunkName: "Taxes" */ './views/taxes/index.vue');
const GainLossReports = () => import(/* webpackChunkName: "Taxes" */ './views/taxes/reports.vue');
const Payroll = () => import(/* webpackChunkName: "Payroll" */ './views/Payroll.vue');
const Settings = () => import(/* webpackChunkName: "Settings" */ './views/settings/indexNew.vue');
const Invitations = () => import(/* webpackChunkName: "Invitations" */ './views/settings/Invitations.vue');
const Users = () => import(/* webpackChunkName: "Users" */ './views/settings/Users.vue');
const Connections = () => import(/* webpackChunkName: "Connections" */ './views/settings/Connections.vue');
const AccountingSettings = () => import(/* webpackChunkName: "Accounting" */ './views/settings/Accounting.vue');
const WalletSettings = () => import(/* webpackChunkName: "WalletSettings" */ './views/settings/Wallet.vue');
const API = () => import(/* webpackChunkName: "API" */ './views/settings/Api.vue');
const Billing = () => import(/* webpackChunkName: "Billing" */ './views/settings/Billing.vue');
const SingleSignon = () => import(/* webpackChunkName: "SingleSignon" */ './views/settings/SingleSignon.vue');
const Invoicing = () => import(/* webpackChunkName: "Invoicing" */ './views/settings/Invoicing.vue');
const ReportsNew = () => import(/* webpackChunkName: "Reports" */ './views/reports/ReportsNew.vue');
const BalanceReport = () => import(/* webpackChunkName: "BalanceReport" */ './views/reports/BalanceReport2.vue');
const InventoryBalanceCheckReport = () =>
  import(/* webpackChunkName: "InventoryBalanceCheckReport" */ './views/reports/InventoryBalanceCheckReport.vue');
const JournalReport = () => import(/* webpackChunkName: "JournalReport" */ './views/reports/JournalReportWrapper.vue');
const ExpandedJournalReport = () =>
  import(/* webpackChunkName: "JournalReport" */ './views/reports/ExpandedJournalReport.vue');
const ExpandedBalanceReport = () =>
  import(/* webpackChunkName: "BalanceReport" */ './views/reports/ExpandedBalanceReport.vue');
const RolledUpJournalReport = () =>
  import(/* webpackChunkName: "RolledUpJournalReport" */ './views/reports/RolledUpJournalReportWrapper.vue');
const LedgerReport = () => import(/* webpackChunkName: "LedgerReport" */ './views/reports/LedgerReport.vue');
const Exports = () => import(/* webpackChunkName: "Export2" */ './views/reports/exports/index.vue');

const InventoryViews = () => import(/* webpackChunkName: "InventoryViews" */ './views/inventory/InventoryViews.vue');
const ScenarioRunner = () => import(/* webpackChunkName: "InventoryViews" */ './views/inventory/ScenarioRunner.vue');

const AccountSettings = () => import(/* webpackChunkName: "AccountSettings" */ './components/user/AccountSettings.vue');
const CompanySettings = () => import(/* webpackChunkName: "CompanySettings" */ './views/CompanySettings.vue');
const Tools = () => import(/* webpackChunkName: "Tools" */ './views/Tools.vue');
const NotFound = () => import(/* webpackChunkName: "NotFound" */ './views/NotFound.vue');
const Accounting = () => import(/* webpackChunkName: "Accounting" */ './views/settings/Accounting.vue');
const Reconcile = () => import(/* webpackChunkName: "Reconcile" */ './views/accounting/Reconcile.vue');
const VendorBills = () => import(/* webpackChunkName: "VendorBills" */ './views/VendorBills.vue');
const CompanyInvoices = () => import(/* webpackChunkName: "CompanyInvoices" */ './views/CompanyInvoices.vue');
const SSO = () => import(/* webpackChunkName: "SSO" */ './views/SSO.vue');
const SCIM = () => import(/* webpackChunkName: "SSO" */ './views/SCIM.vue');
const AccountReceivables = () =>
  import(/* webpackChunkName: "AccountReceivables" */ './views/account_receivables/index.vue');
const Customers = () => import(/* webpackChunkName: "Customers" */ './views/Customers.vue');
// Web-App 2.0
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ './views/Dashboard.vue');
const AccountingDashboard = () => import(/* webpackChunkName: "AccountingDashboard" */ './views/Dashboard2.vue');
const PortfolioDashboard = () =>
  import(/* webpackChunkName: "PortfolioDashboard" */ './views/dashboards/PortfolioDashboard.vue');
const NFTDashboard = () => import(/* webpackChunkName: "NFTDashboard" */ './views/NFTDashboard.vue');
const TxnSummaryDashboard = () =>
  import(/* webpackChunkName: "TxnSummaryDashboard" */ './views/dashboards/TxnSummaryDashboard.vue');
const Pricing = () => import(/* webpackChunkName: "Pricing" */ './views/pricing/Pricing.vue');
const Register = () => import(/* webpackChunkName: "Register" */ './views/Register.vue');
const PricingRules = () => import(/* webpackChunkName: "PricingRules" */ './views/pricing/PricingRules.vue');
const RateTable = () => import(/* webpackChunkName: "RateTable" */ './views/pricing/RateTable.vue');
const Explore = () => import(/* webpackChunkName: "Register" */ './views/data/Explore.vue');
const ExploreV2 = () => import(/* webpackChunkName: "Register" */ './views/dataV2/Explore.vue');
const Rollups = () => import(/* webpackChunkName: "Register" */ './views/data/Rollups.vue');
const Transforms = () => import(/* webpackChunkName: "Transforms" */ './views/data/Transforms.vue');
const Load = () => import(/* webpackChunkName: "Register" */ './views/data/Load.vue');
const Reconciliation = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/Reconciliation.vue');
const ReconciliationDetails = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/ReconciliationDetails.vue');
const ReconciliationsDetails = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/ReconciliationsDetails.vue');
const ReconciliationTemplates = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/ReconciliationTemplates.vue');
const ReconciliationComparison = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/ReconciliationComparison.vue');
const ReconciliationsComparison = () =>
  import(/* webpackChunkName: "Reconciliation" */ './views/reconciliation/ReconciliationsComparison.vue');
const Feeds = () => import(/* webpackChunkName: "Feeds" */ './views/data/Feeds.vue');
const FeedsV2 = () => import(/* webpackChunkName: "Feeds" */ './views/dataV2/Feeds.vue');
const FeedExecutions = () => import(/* webpackChunkName: "Feeds" */ './views/data/FeedExecutions.vue');
const Schemas = () => import(/* webpackChunkName: "Schemas" */ './views/data/Schemas.vue');
const Invoices = () => import(/* webpackChunkName: "Invoices" */ './views/invoicing/Invoices.vue');
const DataSources = () => import(/* webpackChunkName: "DataSources" */ './views/data/DataSources.vue');
const DataSourcesV2 = () => import(/* webpackChunkName: "DataSources" */ './views/dataV2/DataSources.vue');
const AccountingConnections = () =>
  import(/* webpackChunkName: "Connections" */ './views/settings/AccountingConnections.vue');
const SystemJobs = () => import(/* webpackChunkName: "SystemJobs" */ './views/SystemJobs.vue');
const Marketplace = () => import(/* webpackChunkName: "Marketplace" */ './views/marketplace/Marketplace.vue');
const Subsidiaries = () => import(/* webpackChunkName: "Subsidiaries" */ './views/settings/Subsidiaries.vue');
const Clients = () => import(/* webpackChunkName: "Clients" */ './views/Clients.vue');
const Client1099 = () => import(/* webpackChunkName: "Clients" */ './views/clients/1099.vue');
const OrgTokenFiltering = () => import(/* webpackChunkName: "Marketplace" */ './views/filtering/OrgTokenFiltering.vue');
const PeriodClose = () => import(/* webpackChunkName: "PeriodClose" */ './views/PeriodClose.vue');
const DeFiDashboard = () => import(/* webpackChunkName: "DeFi" */ './views/DeFi.vue');
const BIReports = () => import(/* webpackChunkName: "LookerReport" */ './views/BIReports.vue');
const Onramp = () => import(/* webpackChunkName: "Onramp" */ './views/Onramp.vue');
const Roles = () => import(/* webpackChunkName: "Roles" */ './views/Roles.vue');
const AuditLog = () => import(/* webpackChunkName: "AuditLog" */ './views/AuditLog.vue');
const Blank = () => import(/* webpackChunkName: "Blank" */ './views/Blank.vue');

const InventoryManagement = () =>
  import(/* webpackChunkName: "InventoryManagement" */ './views/inventory/InventoryManagement.vue');

const WalletsRoles = () => import(/* webpackChunkName: "WalletsRoles" */ './views/wallets/WalletsRoles.vue');

// Silence navigation duplicated errors (they are harmless)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(this: Router, location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call<Router, [RawLocation], Promise<Route>>(this, location).catch((err) => {
    if (isErrorLike(err) && err.name === 'NavigationDuplicated') {
      return;
    }

    return Promise.reject(err);
  });
} as typeof Router.prototype.push;

Vue.use(Router);

const router = new Router({
  mode: window.self !== window.top ? 'abstract' : undefined,
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/blank',
      component: Blank,
      name: 'blank',
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/auth',
      alias: '/auth-redirect',
      name: 'auth',
      component: Auth,
      meta: {
        readOnly: true,
      },
    },
    {
      path: '/signout',
      name: 'signout',
      component: SignOut,
      meta: {
        requiresAuth: false,
        readOnly: true,
      },
    },
    {
      path: '/account_settings',
      name: 'account',
      component: AccountSettings,
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      props: true,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.OrgRead,
      },
    },
    {
      path: '/invitations',
      name: 'Invitations',
      component: Invitations,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.UsersRead,
      },
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.UsersRead,
      },
    },
    {
      path: '/connections',
      name: 'Connections',
      component: Connections,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.ConnectionsRead,
      },
    },
    {
      path: '/accounting_settings',
      name: 'Accounting',
      component: AccountingSettings,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.AccountingSetupRead,
      },
    },
    {
      path: '/inventory/management',
      name: 'Inventory Management',
      component: InventoryManagement,
      meta: { requiresAuth: true },
    },
    {
      path: '/wallets_settings',
      name: 'Wallet Settings',
      component: WalletSettings,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.WalletsDelete,
      },
    },
    {
      path: '/api',
      name: 'API',
      component: API,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.ApiRead,
      },
    },
    // {
    //   path: '/billing',
    //   name: 'Billing',
    //   component: Billing,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: '/single_sign_on',
    //   name: 'Single Sign-on',
    //   component: SingleSignon,
    //   meta: {
    //     requiresAuth: true,
    //     requiresOrgAdmin: true,
    //     readOnly: false,
    //     allowedRoles: [4],
    //   },
    // },
    {
      path: '/invoicing',
      name: 'Invoicing',
      component: Invoicing,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.InvoicingSettingsRead,
      },
    },
    {
      path: '/success',
      redirect: '/home',
      meta: {
        readOnly: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/portfoliodashboard',
      name: 'portfolioDashboard',
      component: PortfolioDashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/wallets',
      name: 'wallets',
      component: Wallets,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.WalletsRead,
      },
    },
    {
      path: '/wallets/roles',
      name: 'walletsRoles',
      component: WalletsRoles,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.WalletsRead,
      },
    },
    // {
    //   path: "/invoices",
    //   name: "invoices",
    //   component: Invoices,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/transactions',
      name: 'transactions',
      component: Transactions,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TransactionsRead,
      },
    },
    {
      path: '/transactions/:transactionId',
      name: 'transactionsDrilldown',
      component: Transactions,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TransactionsRead,
      },
    },
    {
      path: '/rules',
      name: 'rules',
      component: Rules,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.RulesRead,
      },
    },
    {
      path: '/import',
      name: 'import',
      component: Import,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.ImportRead,
      },
    },
    {
      path: '/matching',
      name: 'Matching',
      component: Matching,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TransactionsRead,
      },
    },
    {
      path: '/importv2',
      name: 'dataImport',
      component: DataImport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataImportRead,
      },
    },
    {
      path: '/importv2/wizard/:reportId',
      name: 'DataImportWizard',
      component: DataImportWizard,
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/importv2/:workflowDefinitionId/:workflowExecutionId',
      name: 'dataImportReport',
      component: DataImportReport,
      meta: {
        requiresAuth: true,
        readOnly: false,
      },
    },
    {
      path: '/categories',
      name: 'categories',
      component: Categories,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.CategoriesRead,
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.ContactsRead,
      },
    },
    {
      path: '/external_cost_basis',
      name: 'external cost basis',
      component: ExternalCostBasis,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.CostBasisRead,
      },
    },
    {
      path: '/period_close',
      name: 'perion end close',
      component: PeriodClose,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.PeriodCloseRead,
      },
    },
    {
      path: '/crypto_bills',
      name: 'Crypto Bills',
      component: CryptoBills,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.CryptoBillsRead,
      },
    },
    {
      path: '/bulk_payment',
      name: 'Bulk Payment',
      component: BulkPayment,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.BillPaymentsRead,
      },
    },
    {
      path: '/wrapping_treatment',
      name: 'Wrapping Treatment',
      component: WrappingTreatment,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.WrappingTreatmentRead,
      },
    },
    {
      path: '/tax_strategy',
      name: 'Tax Strategy',
      component: TaxStrategy,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TaxStrategyRead,
      },
    },
    {
      path: '/trading',
      name: 'trading',
      component: Trading,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TransactionsRead,
      },
    },
    {
      path: '/accounting',
      name: 'accounting',
      component: Accounting,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.AccountingSetupRead,
      },
    },
    {
      path: '/reconcile',
      name: 'Reconcile',
      component: Reconcile as any,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.TransactionReconcileRead,
      },
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: CompanyInvoices,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.InvoicesRead,
      },
    },
    {
      path: '/bills',
      name: 'Bills',
      component: VendorBills,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.BillsRead,
      },
    },
    {
      path: '/sso',
      name: 'SSO',
      component: SSO,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.SsoRead,
      },
    },
    {
      path: '/scim',
      name: 'SCIM',
      component: SCIM,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.Scim,
      },
    },
    {
      path: '/gl/',
      name: 'Gain / Loss',
      redirect: '/gl/scenario',
    },
    {
      path: '/gl/scenario',
      name: 'Gain / Loss Scenario Runner',
      component: Taxes,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.GainLossCreate,
      },
    },
    {
      path: '/gl/reports',
      name: 'Gain / Loss Reports',
      component: GainLossReports,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.GainLossRead,
      },
    },
    {
      path: '/inventory/views',
      name: 'Inventory Views',
      component: InventoryViews,
      meta: {
        requiresAuth: true,
        scope: ScopeLiterals.InventoryViewList,
      },
    },
    {
      path: '/inventory/scenario',
      name: 'Scenario Runner',
      component: ScenarioRunner,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.GainLossCreate,
      },
    },
    // {
    //   path: '/payroll',
    //   name: 'payroll',
    //   component: Payroll,
    //   meta: {
    //     requiresAuth: true,
    //     readOnly: false,
    //     allowedRoles: [1, 2, 3, 4, 5],
    //   },
    // },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsNew,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsAll,
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: Customers,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.InvoicesRead,
      },
    },
    // {
    //   path: '/account_receivables',
    //   name: 'Account Receivables',
    //   component: AccountReceivables,
    //   meta: {
    //     requiresAuth: true,
    //     readOnly: false,
    //     allowedRoles: [1, 2, 3, 4, 5],
    //   },
    // },
    {
      path: '/arap/invoices',
      name: 'Invoices',
      component: Invoices,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.InvoicesRead,
      },
    },
    // {
    //   path: '/tools',
    //   name: 'tools',
    //   component: Tools,
    //   meta: {
    //     requiresAuth: true,
    //     readOnly: false,
    //     allowedRoles: [1, 2, 3, 4, 5],
    //   },
    // },
    {
      path: '/defi',
      name: 'defi',
      component: DeFiDashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DeFiDashboardRead,
      },
    },
    {
      path: '/reports/balanceReport',
      name: 'balanceReport',
      component: BalanceReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsBalanceRead,
      },
    },
    {
      path: '/reports/exports',
      name: 'export',
      component: Exports,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsExportRead,
      },
    },
    // {
    //   path: '/reports/deFiRoiReport',
    //   name: 'deFiRoiReport',
    //   component: DeFiRoiReport,
    //   meta: {
    //     requiresAuth: true,
    //     readOnly: true,
    //   },
    // },
    // {
    //   path: '/reports/txnLineReport',
    //   name: 'txnLineReport',
    //   component: TxnLineReport,
    //   meta: {
    //     requiresAuth: true,
    //     readOnly: true,
    //   },
    // },
    {
      path: '/reports/journalReport',
      name: 'journalReport',
      component: JournalReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsJournalRead,
      },
    },
    {
      path: '/reports/expandedJournalReport',
      name: 'expandedJournalReport',
      component: ExpandedJournalReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsJournalRead,
      },
    },
    {
      path: '/reports/expandedBalanceReport',
      name: 'expandedBalanceReport',
      component: ExpandedBalanceReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsBalanceRead,
      },
    },
    {
      path: '/reports/rolledUpJournalReport',
      name: 'rolledUpJournalReport',
      component: RolledUpJournalReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsJournalRead,
      },
    },
    {
      path: '/reports/ledgerReport',
      name: 'ledgerReport',
      component: LedgerReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsLedgerRead,
      },
    },
    {
      path: '/reports/balanceCheckReport',
      name: 'balanceCheckReport',
      component: InventoryBalanceCheckReport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ReportsBalanceCheckRead,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DashboardRead,
      },
    },
    {
      path: '/accountingdashboard',
      name: 'accountingDashboard',
      component: AccountingDashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DashboardRead,
      },
    },
    {
      path: '/nftdashboard',
      name: 'NFTDashboard',
      component: NFTDashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DashboardRead,
      },
    },
    {
      path: '/txnsummarydashboard',
      name: 'TxnSummaryDashboard',
      component: TxnSummaryDashboard,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DashboardRead,
      },
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.PricingHistoryRead,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.TransactionRegisterRead,
      },
    },
    {
      path: '/pricingRule',
      name: 'pricingRule',
      component: PricingRules,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.PricingRulesRead,
      },
    },
    {
      path: '/rateTable',
      name: 'rateTable',
      component: RateTable,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.PricingRulesRead,
      },
    },
    {
      path: '/systemJobs',
      name: 'systemJobs',
      component: SystemJobs,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.SystemJobsRead,
      },
    },
    {
      path: '/subsidiaries',
      name: 'subsidiaries',
      component: Subsidiaries,
      meta: {
        requiresAuth: true,
        readOnly: false,
        scope: ScopeLiterals.SubsidiariesRead,
      },
    },
    {
      path: '/accounting_connections',
      name: 'AccountingConnections',
      component: AccountingConnections,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        readOnly: false,
        scope: ScopeLiterals.AccountingConnectionRead,
      },
    },
    {
      path: '/marketplace',
      name: 'marketplace',
      component: Marketplace,
      meta: {
        requiresAuth: true,
        readOnly: false,
      },
    },
    {
      path: '/data/explore',
      name: 'dataExplore',
      component: Explore,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataExplore,
      },
    },
    {
      path: '/dataV2/explore',
      name: 'dataExploreV2',
      component: ExploreV2,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataExplore,
      },
    },
    {
      path: '/data/rollups',
      name: 'dataRollups',
      component: Rollups,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataRollupsRead,
      },
    },
    {
      path: '/data/reconciliation',
      name: 'dataRollups',
      component: DataReconciliation,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataRollupsRead,
      },
    },
    {
      path: '/reconciliations',
      name: 'reconciliations',
      component: Reconciliations,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataRollupsRead,
      },
    },
    {
      path: '/data/transforms',
      name: 'dataTransforms',
      component: Transforms,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataTransformsRead,
      },
    },
    {
      path: '/data/load',
      name: 'dataLoad',
      component: Load,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataLoadRead,
      },
    },
    {
      path: '/data/feeds',
      name: 'dataFeeds',
      component: Feeds,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataFeedsRead,
      },
    },
    {
      path: '/dataV2/feeds',
      name: 'dataFeedsV2',
      component: FeedsV2,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataFeedsRead,
      },
    },
    {
      path: '/data/feeds/executions',
      name: 'dataFeedExecutions',
      component: FeedExecutions,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataFeedsRead,
      },
    },
    {
      path: '/data/feeds/execution/:feedExecutionId',
      name: 'dataFeedReport',
      component: DataFeedReport,
      meta: {
        requiresAuth: true,
        readOnly: false,
      },
    },
    {
      path: '/data/schemas',
      name: 'dataSchemas',
      component: Schemas,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataSchemasRead,
      },
    },
    {
      path: '/data/import',
      name: 'dataImport',
      component: DataImport,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataImportRead,
      },
    },
    {
      path: '/data/import/wizard/:reportId',
      name: 'DataImportWizard',
      component: DataImportWizard,
      meta: {
        requiresAuth: true,
        readOnly: true,
      },
    },
    {
      path: '/data/import/:workflowDefinitionId/:workflowExecutionId',
      name: 'dataImportReport',
      component: DataImportReport,
      meta: {
        requiresAuth: true,
        readOnly: false,
      },
    },

    {
      path: '/data/sources',
      name: 'dataSources',
      component: DataSources,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataSourcesRead,
      },
    },
    {
      path: '/dataV2/sources',
      name: 'dataSourcesV2',
      component: DataSourcesV2,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataSourcesRead,
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.ClientsRead,
      },
    },
    {
      path: '/clients/1099',
      name: '1099',
      component: Client1099,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.Clients1099Read,
      },
    },
    {
      path: '/filtering/tokens',
      name: 'tokenFiltering',
      component: OrgTokenFiltering,
      meta: {
        requiresAuth: true,
        readOnly: true,
        requiresOrgAdmin: true,
        scope: ScopeLiterals.TokenFilteringRead,
      },
    },
    {
      path: '/biReports',
      name: 'biReports',
      component: BIReports,
      meta: {
        requiresAuth: true,
        readOnly: true,
        requiresOrgAdmin: true,
        scope: ScopeLiterals.TokenFilteringRead,
      },
    },
    {
      path: '/onramp',
      name: 'onramp',
      component: Onramp,
      meta: {
        requiresAuth: true,
        readOnly: true,
        requiresOrgAdmin: true,
        scope: ScopeLiterals.ProjectRead,
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
        scope: ScopeLiterals.RolesRead,
      },
    },
    {
      path: '/auditLog',
      name: 'auditLog',
      component: AuditLog,
      meta: {
        requiresAuth: true,
        requiresOrgAdmin: true,
      },
    },
    {
      path: '/reconciliation',
      name: 'reconciliation',
      component: Reconciliation,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '/reconciliation/:recId',
      name: 'reconciliationDetails',
      component: ReconciliationDetails,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '/reconciliations/:recId',
      name: 'reconciliationsDetails',
      component: ReconciliationsDetails,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '/reconciliation-templates',
      name: 'reconciliationTemplates',
      component: ReconciliationTemplates,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '/reconciliation/:recId/comparison',
      name: 'reconciliationComparison',
      component: ReconciliationComparison,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '/reconciliations/:recId/comparison',
      name: 'reconciliationsComparison',
      component: ReconciliationsComparison,
      meta: {
        requiresAuth: true,
        readOnly: true,
        scope: ScopeLiterals.DataReconciliationRead,
      },
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound,
      meta: {
        readOnly: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = store.state.user;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const readOnly = to.matched.some((record) => record.meta.readOnly);
  const hash = window.location.hash;
  const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();
  const isAuthEmbedded = hashSearchParams.get('embedded') === 'true';
  if (window.self === window.top || isAuthEmbedded) {
    if (requiresAuth) {
      if (!currentUser) {
        next({
          path: 'auth',
          query:
            to.name !== 'auth'
              ? {
                  ...to.query,
                  redirect: to.fullPath,
                }
              : undefined,
        });
        return;
      }

      // const { isOrgAdmin, isReadOnlyUser } = checkUserRole();

      // if (isOrgAdminRoute(to.path) && !isOrgAdmin) {
      //   next('home');
      //   return;
      // }

      // if (isReadOnlyUser && !readOnly) {
      //   next('home');
      //   return;
      // }

      if (to.meta?.scope && !checkScope(to.meta?.scope)) {
        next('home');
        return;
      }
    }
  }

  next();
});

router.onError((err) => {
  alert(err);
});

export default router;
