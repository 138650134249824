












































































import Cookies from 'js-cookie';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { type Org, ScopeLiterals } from '@/api-svc-types';
import { AuthService } from '@/services/authService';
import { asDefined } from '@/utils/guards';

import { baConfig } from '../../config';
import { BaseVue } from '../BaseVue';
import UiButton from '../components/ui/UiButton.vue';
import UiDropdown from '../components/ui/UiDropdown.vue';
import UiLoading from '../components/ui/UiLoading.vue';
import UiModal from '../components/ui/UiModal.vue';
import UiSelect from '../components/ui/UiSelect.vue';
import UiSelect2 from '../components/ui/UiSelect2.vue';
import type { NavItem } from '../components/ui/UiSidebar.vue';
import UiSidebar from '../components/ui/UiSidebar.vue';
import { checkUserRole, isOrgAdminRoute } from '../utils/security';
import Logo from './Logo.vue';
import CreateOrg from './org/Create.vue';
import TooltipSelect from './tooltip/TooltipSelect.vue';

type NavItemWithMeta = NavItem<{
  allowReadOnly?: boolean;
  featureFlag?: string;
  reverseFeatureFlag?: boolean;
  beta?: boolean;
  isNew?: boolean;
  allowedRoles?: number[];
  scope?: string;
}>;

@Component({
  components: {
    CreateOrg,
    Logo,
    UiSidebar,
    UiSelect,
    UiSelect2,
    UiDropdown,
    UiModal,
    UiButton,
    UiLoading,
    TooltipSelect,
  },
})
export default class NavBar extends BaseVue {
  public childItem = false;
  public childHeight = 0;
  public selectedOrg: Org | null = this.$store.state.currentOrg ?? null;

  public isSelectOrg = false;
  public showDrawer = window.matchMedia('(min-width: 1024px)').matches;
  public parentRouter = null;
  public childRouter = null;
  public variant = false;
  public isCollapse = false;
  public panel = [false, false, false, false, false, false, false, false, false];
  public escalationModalOpen = false;
  public escalationOrg: Org | null = null;
  private authService = new AuthService();

  public topItems = {
    [this.$tc('_account')]: '/account_settings',
    [this.$tc('_signOut')]: '/signout',
  };

  @Prop({ default: true })
  public show!: boolean;

  public newOrgLoading = false;

  public get institutionalMenu(): NavItemWithMeta[] {
    return [
      {
        icon: 'fa-regular fa-chart-line',
        title: this.$tc('_dashboard'),
        children: [
          {
            icon: 'savings',
            title: this.$tc('_portfolio'),
            to: '/home',
            meta: { allowReadOnly: true },
          },
        ],
      },
      {
        icon: 'fa-regular fa-wallet',
        title: this.$tc('_walletsAndConnections', 2),

        children: [
          {
            icon: 'credit_card',
            title: this.$tc('_wallet', 2),
            meta: {
              scope: ScopeLiterals.WalletsRead,
              allowReadOnly: true,
            },
            to: '/wallets',
          },
        ],
      },
      {
        icon: 'fa-regular fa-table-list',
        title: this.$tc('_transaction', 2),
        meta: {},
        children: [
          {
            icon: 'list_alt',
            title: this.$tc('_allTransactions'),
            to: '/transactions',
            meta: {
              scope: ScopeLiterals.TransactionsRead,
              allowReadOnly: true,
            },
          },
        ],
      },
      {
        icon: 'fa-regular fa-warehouse',
        title: this.$tc('_inventory'),
        children: [
          {
            meta: {
              scope: ScopeLiterals.InventoryViewList,
              allowReadOnly: true,
            },
            icon: 'import_export',
            title: this.$tc('_views'),
            to: '/inventory/views',
          },
        ],
      },
      {
        icon: 'fa-solid fa-equals',
        title: 'Reconciliation',
        // meta: { featureFlag: 'institutional' },
        // meta: {
        //   scope: ScopeLiterals.DataReconciliationRead,
        // },
        children: [
          {
            icon: 'fa-regular fa-table-list',
            title: 'List',
            to: '/reconciliations',
            meta: { scope: ScopeLiterals.DataExplore },
          },
          {
            icon: 'group_work',
            title: 'Transaction Rec',
            to: '/data/reconciliation',
            meta: {
              // scope: ScopeLiterals.DataReconciliationRead,
              featureFlag: 'nope',
            },
          },
          {
            icon: 'fa-regular fa-copy',
            title: 'Rec Templates',
            to: '/reconciliation-templates',
            meta: { scope: ScopeLiterals.DataExplore },
          },
        ],
      },
      {
        icon: 'fa-regular fa-magnifying-glass-chart',
        title: this.$tc('_bireports'),
        to: '/biReports',
        meta: { scope: ScopeLiterals.ReportsAll, allowReadOnly: true, featureFlag: 'bi-module' },
      },
      {
        icon: 'fa-solid fa-user',
        title: 'Clients',
        meta: { featureFlag: 'institutional' },
        children: [
          {
            icon: 'group_work',
            title: 'Details',
            to: '/clients',
            meta: { scope: ScopeLiterals.ClientsRead },
          },
          {
            icon: 'group_work',
            title: '1099',
            to: '/clients/1099',
            meta: { scope: ScopeLiterals.Clients1099Read },
          },
        ],
      },
      {
        icon: 'fa-regular fa-file-contract',
        title: this.$tc('_reportsCenter'),
        to: '/reports',
        meta: {
          scope: ScopeLiterals.ReportsAll,
          allowReadOnly: true,
        },
      },
      {
        icon: 'fa-regular fa-dollar-sign',
        title: this.$tc('_pricing'),
        children: [
          {
            icon: 'paid',
            title: this.$tc('_pricing'),
            to: '/pricing',
            meta: {
              scope: ScopeLiterals.PricingHistoryRead,
              allowReadOnly: true,
            },
          },
          {
            icon: 'list',
            title: this.$tc('_pricingRules'),
            to: '/pricingRule',
            meta: {
              featureFlag: 'pricing-rule-ui',
              scope: ScopeLiterals.PricingRulesRead,
            },
          },
        ],
      },
      {
        icon: 'fa-solid fa-database',
        title: this.$tc('_data'),
        meta: { featureFlag: 'data' },
        children: [
          {
            icon: 'group_work',
            title: 'Explore',
            to: '/data/explore',
            meta: { scope: ScopeLiterals.DataExplore },
          },

          {
            icon: 'group_work',
            title: 'Feeds',
            to: '/data/feeds',
            meta: {
              scope: ScopeLiterals.DataFeedsRead,
              featureFlag: 'data-feeds',
            },
          },
          {
            icon: 'group_work',
            title: 'Feed Executions',
            to: '/data/feeds/executions',
            meta: {
              scope: ScopeLiterals.DataFeedsRead,
              featureFlag: 'data-feeds',
            },
          },
          {
            icon: 'group_work',
            title: 'Rollups',
            to: '/data/rollups',
            meta: {
              scope: ScopeLiterals.DataRollupsRead,
              featureFlag: 'data-rollups',
            },
          },
          {
            icon: 'group_work',
            title: 'Transforms',
            to: '/data/transforms',
            meta: {
              scope: ScopeLiterals.DataTransformsRead,
              featureFlag: 'data-rollups',
            },
          },
          {
            icon: 'group_work',
            title: 'Load',
            to: '/data/load',
            meta: {
              scope: ScopeLiterals.DataLoadRead,
              featureFlag: 'data-load',
            },
          },
          {
            icon: 'diagram_project',
            title: 'Schemas',
            to: '/data/schemas',
            meta: {
              scope: ScopeLiterals.DataSchemasRead,
              featureFlag: 'data-schema',
            },
          },
          {
            icon: 'group_work',
            title: 'Data Sources',
            to: '/data/sources',
            meta: {
              scope: ScopeLiterals.DataSourcesRead,
              featureFlag: 'data-datasources',
            },
          },
        ],
      },
      {
        icon: 'fa-solid fa-database',
        title: this.$tc('_dataFusionV2'),
        meta: { featureFlag: 'data-fusion' },
        children: [
          {
            icon: 'group_work',
            title: 'Explore',
            to: '/dataV2/explore',
            meta: { scope: ScopeLiterals.DataExplore },
          },
          {
            icon: 'group_work',
            title: 'Data Sources',
            to: '/dataV2/sources',
            meta: { scope: ScopeLiterals.DataSourcesRead },
          },
          {
            icon: 'group_work',
            title: 'Feeds',
            to: '/dataV2/feeds',
            meta: {
              scope: ScopeLiterals.DataFeedsRead,
            },
          },
        ],
      },
      {
        icon: 'fa-regular fa-folder-gear',
        title: this.$tc('_administration'),
        children: [
          {
            icon: 'group_work',
            title: this.$tc('_orgSettings'),
            to: '/settings',
            meta: { scope: ScopeLiterals.OrgRead },
          },
          {
            icon: 'group_work',
            title: this.$tc('_subsidiary', 2),
            to: '/subsidiaries',
            meta: {
              featureFlag: 'subsidiaries',
              scope: ScopeLiterals.SubsidiariesRead,
            },
          },
          {
            icon: '',
            title: this.$tc('_inventoryManagement'),
            to: '/inventory/management',
            meta: { featureFlag: 'inventory-management' },
          },
          {
            icon: 'receipt_long',
            title: this.$tc('Accounting Setup'),
            to: '/accounting',
            meta: { scope: ScopeLiterals.AccountingSetupRead },
          },
          {
            icon: 'web',
            title: this.$tc('_accountingConnections'),
            to: '/accounting_connections',
            meta: { scope: ScopeLiterals.AccountingConnectionRead },
          },
          {
            icon: 'ballot',
            title: this.$tc('wrapping treatment'),
            to: '/wrapping_treatment',
            meta: { scope: ScopeLiterals.WrappingTreatmentRead },
          },
          {
            icon: 'stacked_bar_chart',
            title: this.$tc('_taxStrategy'),
            to: '/tax_strategy',
            meta: { scope: ScopeLiterals.TaxStrategyRead },
          },
          {
            icon: 'wallet',
            title: this.$tc('_wallet', 2),
            to: '/wallets_settings',
            meta: { scope: ScopeLiterals.WalletsDelete },
          },
          // {
          //   icon: 'post_add',
          //   title: 'Billing',
          //   to: '/billing',
          // },
          {
            icon: 'ballot',
            title: this.$tc('_invoicing'),
            to: '/invoicing',
            meta: {
              scope: ScopeLiterals.InvoicingSettingsRead,
              featureFlag: 'invoices-v2',
              reverseFeatureFlag: true,
            },
          },
          {
            icon: 'fa-regular fa-screwdriver-wrench',
            title: this.$tc('_systemJobs'),
            to: '/systemJobs',
            meta: {
              scope: ScopeLiterals.SystemJobsRead,
              allowReadOnly: false,
            },
          },
          {
            icon: 'fa-regular fa-filter-circle-dollar',
            title: this.$tc('_tokenFiltering'),
            to: '/filtering/tokens',
            meta: { scope: ScopeLiterals.TokenFilteringRead },
          },
          {
            icon: 'fa-regular fa-filter-circle-dollar',
            title: this.$tc('_auditLog'),
            to: '/auditLog',
            meta: {
              scope: ScopeLiterals.OrgRead,
              featureFlag: 'audit-log',
            },
          },
        ],
      },
      {
        icon: 'fa-regular fa-shield-check',
        title: this.$tc('_security'),
        children: [
          {
            icon: 'person',
            title: this.$tc('_users'),
            to: '/users',
            meta: { scope: ScopeLiterals.UsersRead },
          },
          {
            icon: 'role',
            title: this.$tc('_roles'),
            to: '/roles',
            meta: {
              scope: ScopeLiterals.RolesRead,
              featureFlag: 'advanced-rbac',
            },
          },
          {
            icon: 'mark_email_read',
            title: this.$tc('_invitations'),
            to: '/invitations',
            meta: { scope: ScopeLiterals.UsersRead },
          },
          {
            icon: 'mail_lock',
            title: 'SSO',
            to: '/sso',
            meta: { scope: ScopeLiterals.SsoRead },
          },
          {
            icon: 'cloud_circle',
            title: 'API',
            to: '/api',
            meta: { scope: ScopeLiterals.ApiRead },
          },
        ],
      },
      {
        icon: 'fa-solid fa-road',
        title: 'Project Plan',
        to: '/onramp',
        meta: {
          scope: ScopeLiterals.ProjectRead,
          allowReadOnly: true,
          isNew: true,
        },
      },
    ];
  }

  public get items(): NavItemWithMeta[] {
    if (this.checkFeatureFlag('institutional')) {
      return this.institutionalMenu;
    } else {
      return this.primaryMenu;
    }
  }

  public get primaryMenu(): NavItemWithMeta[] {
    return [
      {
        icon: 'fa-regular fa-chart-line',
        title: this.$tc('_dashboard'),
        children: [
          {
            icon: 'savings',
            title: 'Accounting',
            to: '/accountingdashboard',
            meta: {
              featureFlag: 'event-sourced-txns',
              scope: ScopeLiterals.DashboardRead,
              allowReadOnly: true,
            },
          },
          // {
          //   icon: 'savings',
          //   title: this.$tc('_portfolio'),
          //   to: '/home',
          //   meta: { allowReadOnly: true },
          // },
          {
            icon: 'savings',
            title: this.$tc('_portfolio'),
            to: '/portfoliodashboard',
            meta: { allowReadOnly: true },
          },
          {
            icon: 'savings',
            title: 'Treasury',
            to: '/dashboard',
            meta: { featureFlag: 'new-dashboard-ui', allowReadOnly: true },
          },
          {
            icon: 'savings',
            title: 'DeFi',
            to: '/defi',
            meta: { featureFlag: 'advanced-defi', scope: ScopeLiterals.DeFiDashboardRead },
          },
          {
            icon: 'savings',
            title: 'Transaction Summary',
            to: '/txnsummarydashboard',
            meta: { featureFlag: 'event-sourced-txns', allowReadOnly: true },
          },
        ],
      },
      {
        icon: 'fa-regular fa-wallet',
        title: this.$tc('_walletsAndConnections', 2),

        children: [
          {
            icon: 'credit_card',
            title: this.$tc('_wallet', 2),
            meta: { scope: ScopeLiterals.WalletsRead, allowReadOnly: true },
            to: '/wallets',
          },
          {
            icon: 'web',
            title: this.$tc('_connection', 2),
            meta: { scope: ScopeLiterals.ConnectionsRead },
            to: '/connections',
          },
          {
            icon: 'group',
            title: this.$tc('_role', 2),
            meta: { featureFlag: 'soda-report', scope: ScopeLiterals.WalletsRead },
            to: '/wallets/roles',
          },
        ],
      },
      {
        icon: 'fa-regular fa-table-list',
        title: this.$tc('_transaction', 2),
        meta: {},
        children: [
          {
            icon: 'list_alt',
            title: this.$tc('_allTransactions'),
            to: '/transactions',
            meta: {
              scope: ScopeLiterals.TransactionsRead,
              allowReadOnly: true,
            },
          },
          {
            icon: 'list_alt',
            title: this.$tc('_register'),
            to: '/register',
            meta: { scope: ScopeLiterals.TransactionRegisterRead },
          },
          {
            icon: 'swap_horiz',
            title: this.$tc('_trading'),
            to: '/trading',
            meta: { scope: ScopeLiterals.TransactionsRead },
          },
          {
            icon: 'list',
            title: this.$tc('_rules'),
            to: '/rules',
            meta: { scope: ScopeLiterals.RulesRead },
          },
          {
            icon: 'download',
            title: this.$tc('_import'),
            to: '/import',
            meta: { scope: ScopeLiterals.ImportRead },
          },
          {
            icon: 'download',
            title: this.$tc('_importv2'),
            to: '/importv2',
            meta: { scope: ScopeLiterals.ImportRead },
          },
          {
            icon: 'upload',
            title: this.$tc('_export'),
            to: '/reports/exports',
            meta: { scope: ScopeLiterals.ReportsExportRead },
          },
        ],
      },
      {
        icon: 'fa-regular fa-coins',
        title: 'NFTs',
        meta: {},
        children: [
          {
            icon: 'fa-regular fa-coins',
            title: 'NFT Dashboard',
            to: '/nftdashboard',
            meta: { featureFlag: 'nft-dashboard-ui', scope: ScopeLiterals.ReportsExportRead },
          },
        ],
      },
      {
        icon: 'fa-regular fa-building-columns',
        title: this.$tc('_accounting'),

        children: [
          {
            icon: 'fact_check',
            title: this.$tc('_reconcile'),
            to: '/reconcile',
            meta: { scope: ScopeLiterals.TransactionReconcileRead },
          },
          {
            icon: 'apps',
            title: this.$tc('_categories'),
            to: '/categories',
            meta: { scope: ScopeLiterals.CategoriesRead },
          },
          {
            icon: 'perm_contact_calendar',
            title: this.$tc('_contacts'),
            to: '/contacts',
            meta: { scope: ScopeLiterals.ContactsRead },
          },
          {
            icon: 'view_in_ar',
            title: this.$tc('_externalCostBasis'),
            to: '/external_cost_basis',
            meta: { scope: ScopeLiterals.CostBasisRead },
          },
          {
            title: this.$tc('_periodEndClose'),
            to: '/period_close',
            meta: { scope: ScopeLiterals.PeriodCloseRead },
          },
        ],
      },
      {
        icon: 'fa-regular fa-money-bill-trend-up',
        title: this.$tc('_gainLoss'),
        meta: { featureFlag: 'event-sourced-txns', reverseFeatureFlag: true },

        children: [
          {
            meta: { scope: ScopeLiterals.GainLossCreate, allowReadOnly: true },
            icon: 'import_export',
            title: this.$tc('_scenarioRunner'),
            to: '/gl/scenario',
          },
          {
            meta: { scope: ScopeLiterals.GainLossRead, allowReadOnly: true },
            icon: 'import_export',
            title: this.$tc('_reports'),
            to: '/gl/reports',
          },
        ],
      },
      {
        icon: 'fa-regular fa-warehouse',
        title: this.$tc('_inventory'),
        children: [
          {
            meta: { scope: ScopeLiterals.InventoryViewList, allowReadOnly: true },
            icon: 'import_export',
            title: this.$tc('_views'),
            to: '/inventory/views',
          },
          // {
          //   meta: { allowReadOnly: true },
          //   icon: 'import_export',
          //   title: 'Scenario Runner',
          //   to: '/inventory/scenario',
          // },
        ],
      },
      {
        icon: 'fa-regular fa-money-bill-transfer',
        title: this.$tc('_arAp'),

        children: [
          {
            icon: 'receipt_long',
            title: this.$tc('_invoice', 2),
            to: '/invoices',
            meta: {
              scope: ScopeLiterals.InvoicesRead,
              featureFlag: 'invoices-v2',
            },
          },
          {
            icon: 'receipt_long',
            title: 'Bills',
            to: '/bills',
            meta: {
              scope: ScopeLiterals.BillsRead,
              featureFlag: 'invoices-v2',
            },
          },
          {
            icon: 'list_alt',
            title: this.$tc('_customerInvoices'),
            to: '/customers',
            meta: { scope: ScopeLiterals.InvoicesRead },
          },
          // {
          //   icon: 'list_alt',
          //   title: this.$tc('_invoices'),
          //   to: '/arap/invoices',
          //   meta: {
          //     featureFlag: 'invoices-v2',
          //     beta: true,
          //     scope: ScopeLiterals.InvoicesRead,
          //   },
          // },
          {
            icon: 'receipt_long',
            title: this.$tc('_cryptoBills'),
            to: '/crypto_bills',
            meta: { scope: ScopeLiterals.CryptoBillsRead },
          },
          {
            icon: 'receipt_long',
            title: this.$tc('_bulkPayment'),
            to: '/bulk_payment',
            meta: { scope: ScopeLiterals.BillPaymentsRead },
          },
          {
            icon: 'exchange',
            title: this.$tc('_invoiceMatch'),
            to: '/matching',
            meta: {
              scope: ScopeLiterals.TransactionsRead, // NOTE: this should also have InvoicesRead scope
              featureFlag: 'invoice-matching-ui',
            },
          },
        ],
      },
      {
        icon: 'fa-regular fa-dollar-sign',
        title: this.$tc('_pricing'),
        children: [
          {
            icon: 'paid',
            title: this.$tc('_pricing'),
            to: '/pricing',
            meta: { scope: ScopeLiterals.PricingHistoryRead, allowReadOnly: true },
          },
          {
            icon: 'list',
            title: this.$tc('_pricingRules'),
            to: '/pricingRule',
            meta: { featureFlag: 'pricing-rule-ui', scope: ScopeLiterals.PricingRulesRead },
          },
          {
            icon: 'list',
            title: this.$tc('_rateTable'),
            to: '/rateTable',
            meta: { featureFlag: 'rate-table', scope: ScopeLiterals.PricingRulesRead },
          },
        ],
      },
      {
        icon: 'fa-regular fa-file-contract',
        title: this.$tc('_reportsCenter'),
        to: '/reports',
        meta: { scope: ScopeLiterals.ReportsAll, allowReadOnly: true },
      },
      {
        icon: 'fa-regular fa-magnifying-glass-chart',
        title: this.$tc('_bireports'),
        to: '/biReports',
        meta: { scope: ScopeLiterals.ReportsAll, allowReadOnly: true, featureFlag: 'bi-module' },
      },
      {
        icon: 'fa-solid fa-database',
        title: this.$tc('_data'),
        meta: { featureFlag: 'data' },
        children: [
          {
            icon: 'group_work',
            title: 'Explore',
            to: '/data/explore',
            meta: { scope: ScopeLiterals.DataExplore },
          },
          {
            icon: 'group_work',
            title: 'Reconciliation',
            to: '/data/reconciliations',
            meta: { scope: ScopeLiterals.DataReconciliationRead, featureFlag: 'data-reconciliation' },
          },
          {
            icon: 'group_work',
            title: 'Feeds',
            to: '/data/feeds',
            meta: { scope: ScopeLiterals.DataFeedsRead, featureFlag: 'data-feeds' },
          },
          {
            icon: 'group_work',
            title: 'Feed Executions',
            to: '/data/feeds/executions',
            meta: { scope: ScopeLiterals.DataFeedsRead, featureFlag: 'data-feeds' },
          },
          {
            icon: 'group_work',
            title: 'Rollups',
            to: '/data/rollups',
            meta: { scope: ScopeLiterals.DataRollupsRead, featureFlag: 'data-rollups' },
          },
          {
            icon: 'group_work',
            title: 'Transforms',
            to: '/data/transforms',
            meta: { scope: ScopeLiterals.DataTransformsRead, featureFlag: 'data-rollups' },
          },
          {
            icon: 'group_work',
            title: 'Load',
            to: '/data/load',
            meta: { scope: ScopeLiterals.DataLoadRead, featureFlag: 'data-load' },
          },
          {
            icon: 'diagram_project',
            title: 'Schemas',
            to: '/data/schemas',
            meta: { scope: ScopeLiterals.DataSchemasRead, featureFlag: 'data-schema' },
          },
          // {
          //   icon: 'download',
          //   title: 'Import',
          //   to: '/data/import',
          //   meta: { scope: ScopeLiterals.DataImportRead },
          // },
          {
            icon: 'group_work',
            title: 'Data Sources',
            to: '/data/sources',
            meta: { scope: ScopeLiterals.DataSourcesRead, featureFlag: 'data-datasources' },
          },
        ],
      },
      {
        icon: 'fa-solid fa-database',
        title: this.$tc('_dataFusionV2'),
        meta: { featureFlag: 'data-fusion' },
        children: [
          {
            icon: 'group_work',
            title: 'Explore',
            to: '/dataV2/explore',
            meta: { scope: ScopeLiterals.DataExplore },
          },
          {
            icon: 'group_work',
            title: 'Data Sources',
            to: '/dataV2/sources',
            meta: { scope: ScopeLiterals.DataSourcesRead },
          },
          {
            icon: 'group_work',
            title: 'Feeds',
            to: '/dataV2/feeds',
            meta: {
              scope: ScopeLiterals.DataFeedsRead,
            },
          },
        ],
      },
      {
        icon: 'fa-regular fa-folder-gear',
        title: this.$tc('_administration'),
        children: [
          {
            icon: 'group_work',
            title: this.$tc('_orgSettings'),
            to: '/settings',
            meta: { scope: ScopeLiterals.OrgRead },
          },
          {
            icon: 'group_work',
            title: this.$tc('_subsidiary', 2),
            to: '/subsidiaries',
            meta: { featureFlag: 'subsidiaries', scope: ScopeLiterals.SubsidiariesRead },
          },
          {
            icon: '',
            title: this.$tc('_inventoryManagement'),
            to: '/inventory/management',
            meta: { featureFlag: 'inventory-management' },
          },
          {
            icon: 'receipt_long',
            title: this.$tc('Accounting Setup'),
            to: '/accounting',
            meta: { scope: ScopeLiterals.AccountingSetupRead },
          },
          {
            icon: 'web',
            title: this.$tc('_accountingConnections'),
            to: '/accounting_connections',
            meta: { scope: ScopeLiterals.AccountingConnectionRead },
          },
          {
            icon: 'ballot',
            title: this.$tc('wrapping treatment'),
            to: '/wrapping_treatment',
            meta: { scope: ScopeLiterals.WrappingTreatmentRead },
          },
          {
            icon: 'stacked_bar_chart',
            title: this.$tc('_taxStrategy'),
            to: '/tax_strategy',
            meta: { featureFlag: 'event-sourced-txns', reverseFeatureFlag: true, scope: ScopeLiterals.TaxStrategyRead },
          },
          {
            icon: 'wallet',
            title: this.$tc('_wallet', 2),
            to: '/wallets_settings',
            meta: { scope: ScopeLiterals.WalletsDelete },
          },
          // {
          //   icon: 'post_add',
          //   title: 'Billing',
          //   to: '/billing',
          // },
          {
            icon: 'ballot',
            title: this.$tc('_invoicing'),
            to: '/invoicing',
            meta: { scope: ScopeLiterals.InvoicingSettingsRead, featureFlag: 'invoices-v2', reverseFeatureFlag: true },
          },
          {
            icon: 'fa-regular fa-screwdriver-wrench',
            title: this.$tc('_systemJobs'),
            to: '/systemJobs',
            meta: { scope: ScopeLiterals.SystemJobsRead, allowReadOnly: false },
          },
          {
            icon: 'fa-regular fa-filter-circle-dollar',
            title: this.$tc('_tokenFiltering'),
            to: '/filtering/tokens',
            meta: { scope: ScopeLiterals.TokenFilteringRead },
          },
          {
            icon: 'fa-regular fa-filter-circle-dollar',
            title: this.$tc('_auditLog'),
            to: '/auditLog',
            meta: { scope: ScopeLiterals.OrgRead, featureFlag: 'audit-log' },
          },
        ],
      },
      {
        icon: 'fa-regular fa-shield-check',
        title: this.$tc('_security'),
        children: [
          {
            icon: 'person',
            title: this.$tc('_users'),
            to: '/users',
            meta: { scope: ScopeLiterals.UsersRead },
          },
          {
            icon: 'role',
            title: this.$tc('_roles'),
            to: '/roles',
            meta: { scope: ScopeLiterals.RolesRead, featureFlag: 'advanced-rbac' },
          },
          {
            icon: 'mark_email_read',
            title: this.$tc('_invitations'),
            to: '/invitations',
            meta: { scope: ScopeLiterals.UsersRead },
          },
          {
            icon: 'mail_lock',
            title: 'SSO',
            to: '/sso',
            meta: { scope: ScopeLiterals.SsoRead },
          },
          {
            icon: 'mail_lock',
            title: 'SCIM',
            to: '/scim',
            meta: { scope: ScopeLiterals.Scim, featureFlag: 'scim' },
          },
          {
            icon: 'cloud_circle',
            title: 'API',
            to: '/api',
            meta: { scope: ScopeLiterals.ApiRead },
          },
        ],
      },
      {
        icon: 'fa-solid fa-user',
        title: 'Clients',
        meta: { featureFlag: 'clients' },
        children: [
          {
            icon: 'group_work',
            title: 'Details',
            to: '/clients',
            meta: { scope: ScopeLiterals.ClientsRead },
          },
          {
            icon: 'group_work',
            title: '1099',
            to: '/clients/1099',
            meta: { scope: ScopeLiterals.Clients1099Read },
          },
        ],
      },
      {
        icon: 'fa-regular fa-tag',
        title: this.$tc('_marketplace'),
        to: '/marketplace',
      },
      {
        icon: 'fa-regular fa-coin',
        title: this.$tc('_bitwaveOps'),
        to: baConfig.opsUrl ?? '',
        newTab: true,
        meta: { featureFlag: 'ops', scope: ScopeLiterals.OpsRead },
      },
      {
        icon: 'fa-regular fa-coin',
        title: this.$tc('_bitwaveOpsV2'),
        to: (baConfig.ops2Url ?? '')
          .concat('?orgId=')
          .concat(this.$store.state.currentOrg ? this.$store.state.currentOrg.id : ''),
        newTab: true,
        meta: { featureFlag: 'ops', scope: ScopeLiterals.OpsRead },
      },
      {
        icon: 'fa-regular fa-coin',
        title: this.$tc('_enterprisePayments'),
        to: (baConfig.enterprisePaymentsUrl ?? '')
          .concat('?orgId=')
          .concat(this.$store.state.currentOrg ? this.$store.state.currentOrg.id : ''),
        newTab: true,
        meta: { featureFlag: 'enterprise-payments', scope: ScopeLiterals.EnterprisePaymentsRead },
      },
      {
        icon: 'fa-regular fa-graduation-cap',
        title: 'BitwaveU',
        to: 'https://university.bitwave.io/',
        newTab: true,
        meta: { isNew: true },
      },
      {
        icon: 'fa-solid fa-road',
        title: 'Project Plan',
        to: '/onramp',
        meta: {
          scope: ScopeLiterals.ProjectRead,
          allowReadOnly: true,
          isNew: true,
        },
      },
    ];
  }

  public get orgName() {
    if (this.currentOrganization) {
      return this.currentOrganization.name;
    } else {
      return this.$tc('_noOrg');
    }
  }

  public get orgs(): Org[] {
    return this.$store.state.orgs;
  }

  public get orgItems() {
    return (
      this.orgs?.map((x) => ({
        ...x,
        icon: this.escalationOrgs.has(x.id ?? '') ? 'fa-solid fa-lock' : undefined,
      })) ?? []
    );
  }

  public get isReadOnly() {
    const { isReadOnlyUser } = checkUserRole();
    return isReadOnlyUser;
  }

  public get currentOrganization() {
    return this.$store.state.currentOrg;
  }

  public get trialExpiry() {
    return this.currentOrganization?.trialExpiry ? new Date(this.currentOrganization.trialExpiry) : undefined;
  }

  public get user() {
    return this.$store.state.user;
  }

  public get escalationOrgs(): Set<string> {
    const esc = this.$store.state.authTokens?.idToken.payload.escalationOrgs;
    return esc ? new Set(esc) : new Set<string>();
  }

  public get userInitials() {
    let initials = '';
    if (this.user && this.user.displayName) {
      const parts = this.user.displayName.split(' ');
      if (parts[0]?.length) {
        initials += parts[0][0];
      }
      if (parts[1]?.length) {
        initials += parts[1][0];
      }
    }
    initials = initials.toUpperCase();
    return initials;
  }

  public get restrictedMenuItems() {
    const { isOrgAdmin, isReadOnlyUser } = checkUserRole();
    const showTrading = this.$store.state.currentOrg?.taxConfig?.capitalizeTradingFees;

    const itemsToShow = this.items.reduce((lastItems, item) => {
      const show = this.showItem(item, isReadOnlyUser, isOrgAdmin, showTrading);
      if (!show) return lastItems;
      if (item.children) {
        const filteredItem = item.children.filter((i) => this.showItem(i, isReadOnlyUser, isOrgAdmin, showTrading));
        return filteredItem.length
          ? [
              ...lastItems,
              {
                ...item,
                children: filteredItem,
              },
            ]
          : lastItems;
      }
      return [...lastItems, item];
    }, [] as NavItemWithMeta[]);

    return itemsToShow;
  }

  public async setCurrentOrg(org: Org | null) {
    if (!org) return;

    window.pendo?.track('Nav - Org Changed', {});

    if (this.escalationOrgs.has(org.id ?? '')) {
      this.selectedOrg = this.currentOrganization;
      this.escalationOrg = org;
      this.escalationModalOpen = true;
      return;
    }
    this.newOrgLoading = true;
    try {
      await this.$store.dispatch('setCurrentOrg', org.id);
    } finally {
      this.newOrgLoading = false;
    }
    if (!org.timezone || !org.baseCurrency) {
      await this.$router.push({
        name: 'Settings',
        params: {
          id: asDefined(org.id),
        },
      });
    }
  }

  public async doEscalation() {
    if (this.user?.id && this.escalationOrg?.id) {
      Cookies.set('orgId', this.escalationOrg.id);
      await this.authService.sendToAuth({
        userId: this.user.id,
        orgId: this.escalationOrg.id,
      });
    }
  }

  public getRoute(parent: NavItemWithMeta) {
    if (!parent.children) {
      return parent.to;
    } else {
      return parent.children[0]?.to;
    }
  }

  public handleShowDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  public handleVariant() {
    this.variant = !this.variant;
    this.isCollapse = !this.isCollapse;
  }

  public showItem(item: NavItemWithMeta, isReadOnlyUser: boolean, isOrgAdmin: boolean, showTrading: boolean): boolean {
    // if (isReadOnlyUser) {
    //   return item.meta?.allowReadOnly;
    // } else if (item.to && typeof item.to === 'string' && isOrgAdminRoute(item.to)) {
    //   return isOrgAdmin;
    // } else if (item.to === '/trading') {
    //   return showTrading;
    // } else if (item.meta?.featureFlag !== undefined) {
    //   return this.checkFeatureFlag(item.meta.featureFlag, this.features);
    // } else {
    //   return true;
    // }
    if (
      item.meta?.reverseFeatureFlag === true &&
      item.meta.featureFlag !== undefined &&
      this.checkFeatureFlag(item.meta.featureFlag)
    ) {
      return false;
    }
    if (
      !item.meta?.reverseFeatureFlag &&
      item.meta?.featureFlag !== undefined &&
      !this.checkFeatureFlag(item.meta.featureFlag, this.features)
    ) {
      return false;
    } else {
      if (item.meta?.scope) {
        if (this.checkScope(item.meta.scope)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  @Watch('currentOrganization', { immediate: true })
  public onCurrentOrgChange(org?: Org) {
    this.selectedOrg = org ?? null;
  }

  allowCreateOrg = false;

  @Watch('$store.state.user')
  orgIdUpdated() {
    this.allowCreateOrg = this.$store.getters.features['create-org'] === 'true';
  }
}
